export const emailInfos = [
	{ subject: 'Geoportal', recipient: 'dicgp.ipuf@pmf.sc.gov.br' },
	{
		subject:
			'Região continental - Pré-consulta de viabilidade para instalação',
		recipient: 'consulta.smdu@pmf.sc.gov.br',
	},
	{
		subject: 'Região continental - Pré-consulta para fins de construção',
		recipient: 'consulta.smdu@pmf.sc.gov.br',
	},
	{
		subject: 'Pré-consulta de viabilidade para instalação',
		recipient: 'consulta.smdu@pmf.sc.gov.br',
	},
	{
		subject: 'Pré-consulta para fins de construção',
		recipient: 'consulta.smdu@pmf.sc.gov.br',
	},
	{
		subject: 'Pré-consulta ambiental',
		recipient: 'delic.floram@pmf.sc.gov.br',
	},
	{
		subject: 'Certidão de confrontantes',
		recipient: 'gti.smf@pmf.sc.gov.br',
	},
	{ subject: 'Download', recipient: 'dicgp.ipuf@pmf.sc.gov.br' },
	{ subject: 'Geoserviços', recipient: 'dicgp.ipuf@pmf.sc.gov.br' },
	{ subject: 'Outros', recipient: 'dicgp.ipuf@pmf.sc.gov.br' },
];
