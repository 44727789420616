import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import {
	FaFacebookSquare,
	FaTwitterSquare,
	FaInstagram,
	FaYoutube,
} from 'react-icons/fa';

import { Container, Item, FollowUs, ImgLink } from './styles';

import ipuflogo from '~/assets/img/ipuflogo.png';
import logo_prefeitura from '~/assets/img/logo_relatorio.png';
import smpu_logo from '~/assets/img/smpu.jpg';

const Footer = () => {
	return (
		<Container>
			<Row>
				<Col md="5">
					<Item className="mb-3">
						<h3>PREFEITURA MUNICIPAL DE FLORIANÓPOLIS</h3>
						<a
							href="https://www.pmf.sc.gov.br/governo/index.php?pagina=govestrutura"
							target="_blank"
							rel="noopener noreferrer"
						>
							Estrutura organizacional
						</a>
						<a
							href="https://redeplanejamento.pmf.sc.gov.br/pt-BR"
							target="_blank"
							rel="noopener noreferrer"
						>
							Secretaria Municipal de Planejamento e Inteligência Urbana
						</a>
						<Link to="/article/diretoria_gest%C3%A3o_territorial">
							Diretoria de Gestão Territorial
						</Link>
						<Link to="/article/geoprocessamento_no_municipio_de_florianopolis">
							História do Geoprocessamento
						</Link>
						<a
							href="https://leismunicipais.com.br/prefeitura/sc/florianopolis/"
							target="_blank"
							rel="noopener noreferrer"
						>
							Leis municipais
						</a>
						<a
							href="https://www.pmf.sc.gov.br/transparencia/index.php"
							target="_blank"
							rel="noopener noreferrer"
						>
							Transparência
						</a>
						<a
							href="https://www.pmf.sc.gov.br/ouvidoria/"
							target="_blank"
							rel="noopener noreferrer"
						>
							Ouvidoria PMF
						</a>
					</Item>
				</Col>
				<Col md="3">
					<Item className="mb-3">
						<h3>AJUDA</h3>
						<Link to="/help">Dúvidas e Contato</Link>
					</Item>
				</Col>
				<Col md="4">
					<Item className="mb-3">
						<h3>SIGA-NOS</h3>
						<FollowUs>
							<ImgLink
								href="http://www.pmf.sc.gov.br/"
								target="_blank"
								rel="noopener noreferrer"
								alt="Prefeitura Municipal de Florianópolis"
								title="Prefeitura Municipal de Florianópolis"
								img={{
									src: logo_prefeitura,
									size: '100%',
								}}
							/>
							<a
								href="https://www.facebook.com/prefeituradeflorianopolis/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<FaFacebookSquare size={24} />
							</a>
							<a
								href="https://www.instagram.com/prefflorianopolis/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<FaInstagram size={24} />
							</a>
							<a
								href="https://www.youtube.com/playlist?list=PLtMqi7ZE12w5VwBgpFv4-CTL4GGqeC6uU"
								target="_blank"
								rel="noopener noreferrer"
							>
								<FaYoutube size={24} />
							</a>
							<a
								href="https://twitter.com/scflorianopolis"
								target="_blank"
								rel="noopener noreferrer"
							>
								<FaTwitterSquare size={24} />
							</a>
						</FollowUs>
						<FollowUs>
							<ImgLink
								href="https://redeplanejamento.pmf.sc.gov.br/pt-BR"
								target="_blank"
								rel="noopener noreferrer"
								alt="Secretaria Municipal de Mobilidade e Planejamento Urbano"
								title="Secretaria Municipal de Mobilidade e Planejamento Urbano"
								img={{
									src: smpu_logo,
									size: '50%',
									position: 'center 70%',
								}}
							/>
							<a
								href="https://www.instagram.com/planejamentourbanofloripa/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<FaInstagram size={24} />
							</a>
						</FollowUs>
					</Item>
				</Col>
			</Row>
		</Container>
	);
};

export default Footer;
