import styled from 'styled-components';
import bg1 from '../../assets/img/bg1.jpg';
import bg2 from '../../assets/img/bg2.jpg';
import bg3 from '../../assets/img/bg3.jpg';

const getRandomImage = () => {
	const images = [bg1, bg2, bg3];
	return images[Math.floor(Math.random() * images.length)];
};

export const Banner = styled.div`
	background-image: url(${getRandomImage()});
	background-repeat: no-repeat;
	background-size: cover;
	height: 80vh;
	min-height: 600px;
	padding-top: 10vh;
	min-width: 100%;
	position: relative;
	&:before {
		background: linear-gradient(180deg,#00092a,#00092a00);
		z-index: 11;
		width: 100%;
		content: '';
		position: absolute;
		height: 80vh;
		min-height: 600px;
		top: 0px;
		left: 0px;
	}
	&:after {
		background-color: rgb(0 9 42 / 0.5);
		z-index: 12;
		width: 100%;
		content: '';
		position: absolute;
		height: 80vh;
		min-height: 600px;
		top: 0px;
		left: 0px;
	}
}


`;

export const Title = styled.div`
	padding: 30px;
	color: #ffff;
	width: 98%;
	max-width: 785px;
	cursor: default;
	min-width: 10%;
	z-index: 20;
	position: relative;
	margin: 0 auto;
	text-align: center;

	h1 {
		margin-bottom: 10px;
		/* font-size: 22pt; */
		font-size: 3.75rem;
		font-weight: 700;
		color: #ffff !important;
		span {
			font-weight: 400;
			font-size: 20pt;
		}
		@media screen and (max-width: 600px){
			font-size: 2.90rem;
		}
	}

	p {
		margin: 0;
	}
`;

export const Field = styled.div`
	width: 100%;
	height: 56px;
	border-radius: 8px;
    backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px); /* For Safari support */
	background-color: rgba(255, 255, 255, 0.3);

	form {
		position: relative;
		svg {
			position: absolute;
			top: 16px;
			left: 15px;
		}
		button {
			position: absolute;
			right: 10px;
			top: 10px;
			height: 40px;
			border: none;
			color: #000;
			border-radius: 5px;
			font-weight: 600;
			padding: 2px 15px;
		}
		label {
			width: 100%;
			height: 56px;
			input {
				background-color: transparent;
				color: #FFF;
				border: none;
				font-size: 1.125rem;
				width: 100%;
				padding: 16px 0 0 80px;
				&::placeholder {
					color: #FFF;
				}
			
				&::-webkit-input-placeholder {
					color: #FFF;
				}

				&::-moz-placeholder {
					color: #FFF;
				}
			
				&:-ms-input-placeholder {
					color: #FFF;
				}
			
				&:-moz-placeholder {
					color: #FFF;
				}

				@media screen and (max-width: 600px){
					padding: 16px 0 0 40px;
				}
			}
		}
	}

	ul {
		margin: 0;
		list-style: none;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 10px 0;
		li {
			a {
				background-color: #FFF;
				border-radius: 20px;
				color: #000;
				padding: 5px 10px;
				margin: 0 7px;
				text-decoration: none;
				font-size: 11px;
				font-weight: 600;
				&:hover {
					background-color: #e0e0e0
				}

				@media screen and (max-width: 600px) {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					font-size: 10px;
					span {
						display: none;
					}
				}
			}
		}
	}
`
