/* eslint-disable react/no-array-index-key */
import React, { useCallback  } from 'react';
import { Container, Modal } from './styles';
import { Button } from 'reactstrap';
import axios from 'axios'


function PDFModal({ closeModal,pdfUrl, title}) {

  const handleDownloadClick = async () => {
		const fullurl = process.env.REACT_APP_GEOWISE_HOST + pdfUrl
		const filename = fullurl.substring(fullurl.lastIndexOf("/") + 1).split("?")[0]
    const response = await fetch(fullurl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

	return (
		<Container>
			<Modal>
				<div className='report-header-container'>
					<h4 className='header-title m-0'>{title}</h4>
					<div className='report-header-actions-container'>
						<Button
							type="button"
							className="mx-1 w-100"
							size="sm"
							color="success"
							onClick={handleDownloadClick}
						>
							Download
						</Button>
						<Button
							type="button"
							className="mx-1 w-100"
							onClick={closeModal}
							size="sm"
							color="danger"
						>
							Fechar
						</Button>						
					</div>
				</div>
				<hr />
				<iframe title="documento" id="pdfframe" src={process.env.REACT_APP_GEOWISE_HOST + pdfUrl} frameborder="0"></iframe>
			</Modal>
		</Container>
	);
}

export default PDFModal;
