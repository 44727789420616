import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import Home from '~/pages/Home';
import Article from '~/pages/Article';
import Map from '~/pages/Map';
import Service from '~/pages/Service';
import Downloads from '~/pages/Downloads';
import Help from '~/pages/Help';
import Geoservices from '~/pages/Geoservices';

import Route from './route';

const Routes = () => (
	<BrowserRouter>
		<Route path="/" component={Home} exact />
		<Route path="/article" component={Article} exact />
		<Route path="/article/:id" component={Article} exact />
		<Route path="/map" component={Map} />
		<Route path="/downloads" component={Downloads} exact />
		<Route path="/downloads/:downloadType" component={Downloads} exact />
		<Route path="/geoservices" component={Geoservices} exact />
		<Route path="/services" component={Service} exact />
		<Route path="/services/:service" component={Service} exact />
		<Route path="/help" component={Help} />
	</BrowserRouter>
);

export default Routes;
