/* eslint-disable no-undef */
import React, { useEffect } from 'react';

import api, { signIn } from '../../services/api';

import { Background } from './styles';

// window.GeoBuilder = window.GeoBuilder || {};

const Map = () => {
	useEffect(() => {
		async function getGeowiseMap() {
			try {
				await signIn();

				const response = await api.get(
					'geowise/mapa/get_map_by_target',
					{
						headers: {
							'Content-Type': 'application/json',
							Accept: 'application/json',
						},
						params: { target: 'geoportal' },
					}
				);

				const config = response.data;

				// eslint-disable-next-line no-new
				const map = new window.GeoBuilder.GeoMap('mapateste', config);

				const measureLayer = new ol.layer.Vector({
					source: new ol.source.Vector(),
					style: new ol.style.Style({
						fill: new ol.style.Fill({
							color: 'rgba(255, 0, 255, 0.2)',
						}),
						stroke: new ol.style.Stroke({
							color: '#f0f',
							width: 2,
						}),
						image: new ol.style.Circle({
							radius: 7,
							fill: new ol.style.Fill({
								color: '#f0f',
							}),
						}),
					}),
				});

				measureLayer.setZIndex(999);
				map.ol.addLayer(measureLayer);

				const draftLayer = new ol.layer.Vector({
					source: new ol.source.Vector(),
					style: new ol.style.Style({
						fill: new ol.style.Fill({
							color: 'rgba(255, 0, 255, 0.2)',
						}),
						stroke: new ol.style.Stroke({
							color: '#f0f',
							width: 2,
						}),
						image: new ol.style.Circle({
							radius: 7,
							fill: new ol.style.Fill({
								color: '#f0f',
							}),
						}),
					}),
				});

				draftLayer.setZIndex(999);
				map.ol.addLayer(draftLayer);

				map.addControl(new window.GeoBuilder.Draw());
				map.addControl(new window.GeoBuilder.GeoDesc());
				map.addControl(new window.GeoBuilder.SaveStatus());

				let center = [0, 0];
				if (config.center && config.center.SRID !== config.SRID) {
					center = ol.proj.transform(
						config.center.coordinates,
						config.center.srid || 'EPSG:4326',
						config.SRID
					);
				}

				const overviewMap = new ol.control.OverviewMap({
					view: new ol.View({
						projection: ol.proj.get(config.SRID),
						center,
					}),
					className: 'ol-overviewmap bottom-overview-map',
					label: '«',
					collapseLabel: '»',
					tipLabel: 'Mapa de localização',
					layers: [
						new ol.layer.Tile({
							source: new ol.source.OSM(),
						}),
					],
				});

				map.ol.addControl(overviewMap);

				// menu esquerdo
				map.addControl(new GeoBuilder.SearchTool({
					position: 'lt',
					class: 'gb-search-control',
					domain: process.env.REACT_APP_GEOWISE_HOST,
					userLogin: process.env.REACT_APP_GEOWISE_LOGIN,
					userAuthToken: process.env.REACT_APP_GEOWISE_AUTH_TOKEN,
					map: map
				}));


				map.addControl(
					new window.GeoBuilder.ZoomExtent({
						extent: [-48.613, -27.847, -48.358593, -27.379],
						srid: 'EPSG:4326',
						forceOnInitialize: false,
						class: 'extent-control',
						position: 'lb',
					})
				);
				map.addControl(
					new window.GeoBuilder.ZoomByRect({
						class: 'gb-zoom-rect-control',
						position: 'lb',
					})
				);
				map.addControl(
					new window.GeoBuilder.ZoomIn({
						position: 'lb',
					})
				);
				map.addControl(
					new window.GeoBuilder.ZoomOut({
						position: 'lb',
					})
				);
				map.addControl(
					new window.GeoBuilder.GoToCoordinate({
						class: 'go-to-control',
						position: 'lb',
					})
				);

				map.addControl(
					new window.GeoBuilder.GoToHome({
						position: 'lb',
					})
				);
				map.addControl(
					new window.GeoBuilder.Undo({
						position: 'lb',
					})
				);
				map.addControl(
					new window.GeoBuilder.Redo({
						position: 'lb',
					})
				);
				map.addControl(
					new window.GeoBuilder.FullScreen({
						position: 'lb',
					})
				);
				map.addControl(
					new window.GeoBuilder.Snap({
						position: 'lb',
					})
				);

				// Menu direito

				map.addControl(
					new window.GeoBuilder.LayerSwitcher({
						position: 'rt',
					})
				);
				map.addControl(
					new window.GeoBuilder.Transparency({
						position: 'rt',
					})
				);
				map.addControl(
					new window.GeoBuilder.PrintMap({
						minMap: overviewMap,
						class: 'print-map-control',
						css: '/openlayers/ol.css',
						position: 'rt',
						templates: [
							{
								path: '/templates/a3_landscape.xhtml',
								name: 'Folha A3 Paisagem - Legenda padrão',
								description: 'Folha A3 no padrão NBR 10068',
								elements: [
									'map',
									'legend',
									'title',
									'vendor',
									'scale',
								],
							},
							{
								path: '/templates/a3_portrait.xhtml',
								name: 'Folha A3 Retrato - Legenda padrão',
								description: 'Folha A3 no padrão NBR 10068',
								elements: [
									'map',
									'legend',
									'title',
									'vendor',
									'scale',
								],
							},
							{
								path: '/templates/a4_landscape.xhtml',
								name: 'Folha A4 Paisagem - Legenda padrão',
								description: 'Folha A4 no padrão NBR 10068',
								elements: [
									'map',
									'legend',
									'title',
									'vendor',
									'scale',
								],
							},
							{
								path: '/templates/a4_portrait.xhtml',
								name: 'Folha A4 Retrato - Legenda padrão',
								description: 'Folha A4 no padrão NBR 10068',
								elements: [
									'map',
									'legend',
									'title',
									'vendor',
									'scale',
								],
								default: true,
							},
						],
					})
				);

				const measureSubpanel = new window.GeoBuilder.GeoSubPanel({
					tip: 'Ferramentas de medida',
					class: 'gb-measure-line-control',
					position: 'rt',
				});

				measureSubpanel.addControl(
					new window.GeoBuilder.SimpleMeasure({
						position: 'lb',
						layer: measureLayer,
						measureType: 'coordinate',
						tip: 'Obter coordenadas',
						class: 'gb-measure-point',
					})
				);

				measureSubpanel.addControl(
					new window.GeoBuilder.SimpleMeasure({
						position: 'lb',
						layer: measureLayer,
						measureType: 'length',
						tip: 'Medir comprimentos',
						class: 'gb-measure-line',
					})
				);

				measureSubpanel.addControl(
					new window.GeoBuilder.SimpleMeasure({
						position: 'lb',
						layer: measureLayer,
						measureType: 'area',
						tip: 'Medir áreas',
						class: 'gb-measure-polygon',
					})
				);

				measureSubpanel.addControl(
					new window.GeoBuilder.SimpleMeasure({
						position: 'lb',
						layer: measureLayer,
						measureType: 'azimuth',
						tip: 'Obter azimute',
						class: 'gb-measure-azimuth',
					})
				);

				measureSubpanel.addControl(
					new window.GeoBuilder.SimpleMeasure({
						position: 'lb',
						layer: measureLayer,
						measureType: 'undo',
						tip: 'Desfazer última medida',
						class: 'gb-undo-control',
					})
				);

				measureSubpanel.addControl(
					new window.GeoBuilder.SimpleMeasure({
						position: 'lb',
						layer: measureLayer,
						measureType: 'clear',
						tip: 'Limpar medidas',
						class: 'gb-measure-clear',
					})
				);

				map.addControl(measureSubpanel);

				const draftSubpanel = new window.GeoBuilder.GeoSubPanel({
					tip: 'Rascunho',
					class: 'gb-draft',
					position: 'rt',
				});

				draftSubpanel.addControl(
					new window.GeoBuilder.SimpleDraft({
						position: 'rt',
						class: 'gb-draft-text',
						draftType: 'text',
						layer: draftLayer,
						tip: 'Inserir Texto',
					})
				);

				draftSubpanel.addControl(
					new window.GeoBuilder.SimpleDraft({
						position: 'rt',
						class: 'gb-measure-point',
						draftType: 'point',
						layer: draftLayer,
						tip: 'Inserir Ponto',
					})
				);

				draftSubpanel.addControl(
					new window.GeoBuilder.SimpleDraft({
						position: 'rt',
						class: 'gb-draft-line',
						draftType: 'line',
						layer: draftLayer,
						tip: 'Inserir Linha',
					})
				);

				draftSubpanel.addControl(
					new window.GeoBuilder.SimpleDraft({
						position: 'rt',
						class: 'gb-measure-polygon',
						draftType: 'polygon',
						layer: draftLayer,
						tip: 'Inserir Polígono',
					})
				);

				draftSubpanel.addControl(
					new window.GeoBuilder.SimpleDraft({
						position: 'rt',
						class: 'gb-draft-arrow',
						draftType: 'arrow',
						layer: draftLayer,
						tip: 'Inserir Seta',
					})
				);

				draftSubpanel.addControl(
					new window.GeoBuilder.SimpleDraft({
						position: 'rt',
						class: 'gb-draft-image',
						draftType: 'image',
						layer: draftLayer,
						tip: 'Inserir Imagem',
					})
				);

				draftSubpanel.addControl(
					new window.GeoBuilder.SimpleDraft({
						position: 'rt',
						class: 'gb-undo-control',
						draftType: 'undo',
						layer: draftLayer,
						tip: 'Desfazer',
					})
				);

				draftSubpanel.addControl(
					new window.GeoBuilder.SimpleDraft({
						position: 'rt',
						class: 'gb-measure-clear',
						draftType: 'clear',
						layer: draftLayer,
						tip: 'Limpar todos os rascunhos',
					})
				);

				map.addControl(draftSubpanel);

				map.addControl(new GeoBuilder.GetMapInfo({
					position: 'rt',
					geowiseApiConfig: {
						url: process.env.REACT_APP_GEOWISE_HOST,
						headers: {
							'X-User-Login': process.env.REACT_APP_GEOWISE_LOGIN || '',
							'X-User-Token': process.env.REACT_APP_GEOWISE_AUTH_TOKEN || '',
						}
					}
				}));

				map.addControl(
					new window.GeoBuilder.OgcExporter({
						docked: true,
						class: 'export-map-control',
						position: 'rt',
					})
				);

				map.addControl(
					new window.GeoBuilder.LayerSwipe({
						class: 'gb-swipe-control',
						position: 'rt',
						tip: 'Layer Swipe',
					})
				);

				// Fim Menu direito

				map.addControl(new window.GeoBuilder.MousePosition());
				map.addControl(new window.GeoBuilder.ScaleLine());
				map.addControl(new window.GeoBuilder.ScaleSelector());
			} catch (error) {
				// console.log('Falha ao carregar o mapa!');
			}
		}
		getGeowiseMap();
	}, []);

	return (
		<Background>
			<div
				id="mapateste"
				style={{
					width: '100%',
					height: 'calc(100% - 70px)',
					position: 'absolute',
					bottom: '0',
					left: '0',
				}}
			/>
		</Background>
	);
};

export default Map;
