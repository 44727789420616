import React from 'react';

import NewsCards from '~/Components/NewsCards';
import ArticleCards from '~/Components/ArticleCards';
import Footer from '~/Components/Footer';
import { Title, Banner, Field } from './styles';
import Alerts from '~/Components/Alert';

const Home = () => {
	return (
		<>
			{
				localStorage.getItem('termgeo') !== 'true' ?
					<Alerts color={'info'}
						title={'Termo de Uso de dados Geoespaciais'}
						textBody={'Aceite sobre os termos de uso de dados geoespaciais.'}

					/>
					: ''
			}
			<Banner>
				<Title>
					<h1><span>Bem vindo ao </span><br />GeoPortal</h1>
					<Field>
						<form action="https://redeplanejamento.pmf.sc.gov.br/pt-BR/resultados-busca" method='get' autoComplete='off' target="_blank">
							<label for="busca">
								<input name='search' type="text" id="busca" placeholder='O que você procura?' />
							</label>
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide-icon lucide lucide-search absolute top-1/2 -translate-y-1/2 sm:right-4 left-[1.125rem] stroke-white">
								<circle cx="11" cy="11" r="8"></circle>
								<path d="m21 21-4.3-4.3">
								</path>
							</svg>
							<button type="submit">Pesquisar</button>
						</form>
						<ul>
							<li>
								<a href="https://redeplanejamento.pmf.sc.gov.br/pt-BR/resultados-busca?tags=eiv" target="_blank">
									estudo <span>de impacto da vizinhança</span>
								</a>
							</li>
							<li>
								<a href="https://redeplanejamento.pmf.sc.gov.br/pt-BR/resultados-busca?tags=plano-diretor" target="_blank">
									plano diretor
								</a>
							</li>
							<li>
								<a href="https://redeplanejamento.pmf.sc.gov.br/pt-BR/resultados-busca?tags=arquitetura-urbana" target="_blank">
									arquitetura urbana
								</a>
							</li>
						</ul>
					</Field>
				</Title>
			</Banner>
			<NewsCards />
			<ArticleCards />
			<Footer />

		</>
	);
};

export default Home;
