import React, { useEffect, useState } from 'react';
// import renderTemplate from 'react-text-templates';

import { useParams, useHistory } from 'react-router-dom';

import Footer from '~/Components/Footer';
import articles from '~/config/articles';

import { Content, Header, Section, Aside, Item, TextArea } from './styles';

const Article = () => {
	const { id } = useParams();
	const history = useHistory();

	// const [data, setData] = useState([]);
	const [atualArticle, setArticle] = useState();

	useEffect(() => {
		if (!id) {
			history.push('/article/geofloripa');
		} else {
			setArticle(articles.find((e) => e.id === id));
		}
	}, [id, history]);

	return (
		<>
			<Content>
				<Aside>
					<h3>Veja também</h3>
					{articles.map((e, i) => (
						<Item
							key={String(i)}
							selected={e.id === id}
							onClick={() => history.push(`/article/${e.id}`)}
						>
							{e.name}
						</Item>
					))}
				</Aside>
				<Section>
					<Header>
						<h1>{atualArticle && atualArticle.name}</h1>
					</Header>
					<TextArea>{atualArticle && atualArticle.content}</TextArea>
				</Section>
			</Content>
			<Footer />
		</>
	);
};

export default Article;
