import React from 'react';
import { useHistory } from 'react-router-dom';

import { Container, MiniCards, CardsTitle, Content } from './styles';
import NewsCard from '../NewsCard';

import { FaMapMarkedAlt, FaRegAddressCard } from "react-icons/fa";
import { MdOutlineSettingsSystemDaydream } from "react-icons/md";
import { PiNewspaperClippingLight } from "react-icons/pi";
import { TbMapCode, TbPhotoPin } from "react-icons/tb";
import { TbInfoSquareRounded } from "react-icons/tb";
import { HiDocumentPlus } from "react-icons/hi2";
import { BsHouseGearFill } from "react-icons/bs";
import { LuConstruction } from "react-icons/lu";
import { IoDownload } from "react-icons/io5";
import { FaTreeCity } from "react-icons/fa6";
import { CgWebsite } from "react-icons/cg";
import { FiLayout } from "react-icons/fi";
import { GoGraph } from "react-icons/go";




const NewsCards = () => {
	const history = useHistory();

	return (
		<Container>
			<Content>
				<MiniCards>
					<NewsCard
						text={['Mapa', 'Interativo']}
						icon={<TbPhotoPin size={22} color="black" />}
						onClick={() => history.push('/map')}
					/>
					<NewsCard
						text={['Emissão de', 'Documentos']}
						icon={<HiDocumentPlus size={22} color="black" />}
						onClick={() => history.push('/services')}
					/>
					<NewsCard
						text={['Consulta', 'de Viabilidade', 'para Instalação']}
						icon={<LuConstruction size={22} color="black" />}
						onClick={() => {
							history.push('/services/consulta-instalacao');
						}}
					/>
					<NewsCard
						text={['Consulta Automatizada para Fins de Construção']}
						icon={<BsHouseGearFill size={22} color="black" />}
						onClick={() => {
							history.push('/services/consulta-construcao');
						}}
					/>
					<NewsCard
						text={['Consulta', 'Ambiental']}
						icon={<FaTreeCity size={22} color="black" />}
						onClick={() => {
							history.push('/services/consulta-ambiental');
						}}
					/>
					<NewsCard
						text={['Geoserviços']}
						icon={<TbMapCode size={22} color="black" />}
						onClick={() => history.push('/geoservices')}
					/>
					<NewsCard
						text={['Downloads']}
						icon={<IoDownload size={22} color="black" />}
						onClick={() => history.push('/downloads')}
					/>
					<NewsCard
						text={['Mapa Físico', 'Político']}
						icon={<FaMapMarkedAlt size={22} color="black" />}
						onClick={() =>
							history.push('/downloads/mapa-fisico-politico')
						}
					/>
					<NewsCard
						text={['Portal', 'Serviços', 'PMF']}
						icon={<CgWebsite size={22} color="black" />}
						onClick={() => {
							const link = document.createElement('a');
							link.href =
								'https://www.pmf.sc.gov.br/servicos/index.php?pagina=servonline';
							link.target = '_blank';
							link.click();
						}}
					/>
					<NewsCard
						text={['Certidão', 'de endereço']}
						icon={<PiNewspaperClippingLight size={22} color="black" />}
						onClick={() => {
							history.push('/services/certidao-endereco');
						}}
					/>
					<NewsCard
						text={['Certidão de', 'Confrontantes']}
						icon={<FaRegAddressCard size={22} color="black" />}
						onClick={() => {
							history.push('/services/certidao-confrontantes');
						}}
					/>
					<NewsCard
						text={['Rede de', 'Planejamento']}
						icon={<FiLayout size={22} color="black" />}
						onClick={() => {
							const link = document.createElement('a');
							link.href =
								'https://redeplanejamento.pmf.sc.gov.br/pt-BR';
							link.target = '_blank';
							link.click();
						}}
					/>
					<NewsCard
						text={['Indicadores de Desempenho Urbano e Territorial']}
						icon={<GoGraph size={22} color="black" />}
						onClick={() => {
							const link = document.createElement('a');
							link.href =
								'https://redeplanejamento.pmf.sc.gov.br/pt-BR/indicadores/lista';
							link.target = '_blank';
							link.click();
						}}
					/>

					<NewsCard
						text={['Sistema Municipal de Informações Urbanísticas']}
						icon={<TbInfoSquareRounded size={22} color="black" />}
						onClick={() => {
							const link = document.createElement('a');
							link.href = '/article/sistema_municipal_de_informacoes_urbanisticas';
							link.click();
						}}
					/>
				</MiniCards>
			</Content>
		</Container>
	);
};

export default NewsCards;