import React from 'react';


import { Container } from './styles';

const NewsCard = ({ color = '#f1f5f9', text = [], onClick, comingSoon = false, icon = '' }) => {
	return (
		<Container color={color} onClick={onClick || (() => { })}>
			{!comingSoon && icon}
			{comingSoon && <span>Em Breve</span>}
			<div>
				<h2>
					{text.map((e, i) => (
						<p key={String(i)}>{e}</p>
					))}
				</h2>
			</div>
		</Container>
	);
};

export default NewsCard;
