import styled, { css } from 'styled-components';

export const Container = styled.div`
	background-color: #fff;
	/* margin-bottom: 20px; */
	margin-bottom: -20px;
	padding: 20px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	max-width: 100%;
	position: relative;
	user-select: none;
	overflow: hidden;
	min-height: 270px;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	max-width: 100%;
	overflow: hidden;
`;

export const Scroller = styled.div`
	position: absolute;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;

	div {
		cursor: pointer;
		background-color: #fff;
		padding: 10px;
		border-radius: 50%;
		transition: transform 200ms ease-in-out;

		&:hover {
			transform: scale(1.1);
			transition: transform 200ms ease-in-out;
		}

		&:active {
			transform: scale(1);
			transition: transform 200ms ease-in-out;
		}
	}

	${({ right }) =>
		right
			? css`
					right: 0;
					top: 0;
			  `
			: css`
					left: 0;
					top: 0;
			  `}
`;

export const ArticleCard = styled.div`
	cursor: pointer;
	color: #002f66;
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: max-content;
	transition: transform 200ms ease-in-out, box-shadow 200ms ease-in-out 55ms;
`;

export const Image = styled.div`
	cursor: default;
	min-height: 200px;
	max-height: 200px;
	min-width: 400px;
	max-width: 400px;
	margin: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-image: url(${({ url }) => url});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	position: relative;

	h3 {
		position: absolute;
		bottom: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.7);
		color: #fff;
		margin: 0;
		padding: 10px;
		font-weight: 500;
		font-size: 1rem;
	}
`;
