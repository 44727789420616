import styled from 'styled-components';

export const Container = styled.div`
	width: 14.96%;
	max-width: 14.96%;
	height: 140px;
	min-height: 140px;
	border-radius: 5px;
	padding: 10px;
	margin: 10px;
	cursor: pointer;
    align-items: center;
	background-color: ${(p) => p.color};
	color: #fff !important;
	text-decoration: none !important;
	position: relative;
	border: 1px solid ${(p) => p.color};
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	@media screen and (min-width: 890px) and (max-width: 1180px) {
		width: 17.66%;
		max-width: 17.66%;
	}
	@media screen and (min-width: 720px) and (max-width: 889px) {
		width: 30.5%;
		max-width: 30.5%;
	}
	@media screen and (min-width: 580px) and (max-width: 720px) {
		width: 45%;
		max-width: 55%;
	}
	@media screen and (max-width: 580px) {
		width: 100%;
		max-width: 100%;
	}
	
	h2 {
		/* font-size: 12pt; */
		font-size: 11pt;
		/*color: #21ade4 !important;*/ 
		color: #1e293b !important;
		font-weight: 700;

		p {
			margin: 0;
			text-align: center;
		}
		
	}

	span {
		font-size: 10pt;
		color: #2c354a;
		text-transform: uppercase;
	}

	div {
		padding-top: 10px;
	}

	&:hover {
		border: 1px solid rgb(33 173 228);
		box-shadow: rgba(0,0,0,0) 0px 0px 0px 0px, rgba(0,0,0,0) 0px 0px 0px 0px, rgba(0,0,0,0.1) 0px 10px 15px -3px, rgba(0,0,0,0.1);

		h2 {
			p {
				color: rgb(33 173 228);
			}
		}
		svg {
			color: rgb(33 173 228) !important;
		}
	}
`;
