import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { FormGroup, Label, Button, Jumbotron, Form } from 'reactstrap';

import Waiter from '~/Components/Waiter';

import { Section } from '../styles';

import api from '../../../services/api';
import PDFModal from '~/Components/PDFModal';

function FormConsultaAmbiental() {
	const [modalVisivel, setModalVisivel] = useState(false);
	const [loading, setLoading] = useState(false);
	const [inscricao, setInscricao] = useState('');
	const [erro, setErro] = useState('');
	const [pdfUrl, setPdfURL] = useState('');

	async function consultarStatusRelatorio(reportId) {
		return new Promise((resolve, reject) => {
			const interval = setInterval(async () => {
				try {
					const { data: statusData } = await api.get(
						`/geowise/reports/${reportId}`
					);

					if (statusData?.report_response?.pdf_url) {
						clearInterval(interval);
						setPdfURL(statusData.report_response.pdf_url);
						setLoading(false);
						setModalVisivel(true);
						setErro('');
						resolve();
					}
				} catch (error) {
					clearInterval(interval);
					reject(error);
				}
			}, 20000);
		});
	}

	async function gerarConsulta() {
		if (inscricao === '' || inscricao.length < 22) {
			setErro('Inscrição imobiliária incompleta.');
			return false;
		}

		try {
			setLoading(true);

			const { data } = await api.post('/urbano/relatorios/consulta_ambiental', { inscricao });

			if (!data?.success) {
				setErro(data?.message || 'Houve um erro ao gerar documento');
			} else if (!data?.report_response?.pdf_url) {
				await consultarStatusRelatorio(data.report_response.report_id);
			} else {
				setPdfURL(data.report_response.pdf_url);
			}

			setLoading(false);
			setModalVisivel(true);
			setErro('');
			return true;
		} catch (error) {
			if (error.isAxiosError) {
				setErro(error.response.data.message || 'Houve um erro ao gerar documento');
			} else {
				setErro('Informações da consulta não encontradas.');
			}
			setLoading(false);
			return false;
		}
	}

	function closeModal() {
		setModalVisivel(false);
	}

	return (
		<>
			{modalVisivel && (
				<PDFModal
					title="Consulta Ambiental"
					closeModal={closeModal}
					pdfUrl={pdfUrl}
				/>
			)}
			{loading && <Waiter />}
			<Section>
				<Jumbotron style={{ background: 'transparent' }}>
					<h1 className="display-6">Consulta Ambiental</h1>
					<p className="lead">Fundação Municipal do Meio Ambiente</p>
					{erro && <span style={{ color: '#ff1912' }}>{erro}</span>}
					<hr className="my-2" />
					<br />
					<Form onSubmit={(e) => e.preventDefault()}>
						<FormGroup>
							<Label htmlFor="inscricao_imobiliaria">
								Inscrição imobiliária
							</Label>
							<InputMask
								type="text"
								mask="99.99.999.9999.999-999"
								className="form-control"
								name="inscricao_imobiliaria"
								id="inscricao_imobiliaria"
								maskChar=""
								placeholder="Ex: 12.34.567.8910.111-213"
								value={inscricao}
								onChange={({ target: { value } }) =>
									setInscricao(value)
								}
							/>
						</FormGroup>
						<FormGroup>
							<Button
								onClick={gerarConsulta}
								color="info"
								type="submit"
							>
								Gerar
							</Button>
						</FormGroup>
					</Form>
				</Jumbotron>
			</Section>
		</>
	);
}

export default FormConsultaAmbiental;
