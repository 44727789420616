import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import { FormGroup, Label, Button, Jumbotron, Form } from 'reactstrap';

import CustomSelect from '~/Components/CustomSelect';
import Waiter from '~/Components/Waiter';

import { Section } from '../styles';

import api, { signIn } from '../../../services/api';
import PDFModal from '~/Components/PDFModal';

function FormConsultaConstrucao() {
	const [modalVisivel, setModalVisivel] = useState(false);
	const [loading, setLoading] = useState(false);
	const [atividades, setAtividades] = useState([]);
	const [inscription, setInscription] = useState('');
	const [usage, setUsage] = useState([]);
	const [erro, setErro] = useState('');
	const [pdfUrl, setPdfURL] = useState('');

	useEffect(() => {
		async function getAtividades() {
			await signIn();

			const response = await api.get('urbano/relatorios/get_usos_construcao', {
				headers: {
					'Content-Type': 'application/json',
				},
			});

			if (response.data.usos_construcao) {
				setAtividades(response.data.usos_construcao);
			}
		}

		getAtividades();
	}, []);

	async function gerarConsulta() {
		if (!inscription || usage.length === 0) {
			setErro('Informações incompletas.');
			return false;
		}

		try {
			setLoading(true);

			const { data } = await api.post(
				'/urbano/relatorios/consulta_viabilidade_para_construcao',
				{
					inscricao: inscription,
					usos_construcao: usage,
				}
			);
			if (!data?.success || !data?.report_response?.pdf_url) {
				setErro(data?.message || 'Houve um erro ao gerar documento');
			}

			setPdfURL(data.report_response.pdf_url);
			setLoading(false);
			setModalVisivel(true);
			setErro('');
			return true;
		} catch (error) {
			if (error.isAxiosError) {
				setErro(
					error.response.data.message ||
						'Houve um erro ao gerar documento'
				);
			} else {
				setErro('Informações da consulta não encontradas.');
			}
			setLoading(false);
		}
		return false;
	}

	function closeModal() {
		setModalVisivel(false);
	}

	return (
		<>
			{modalVisivel && (
				<PDFModal
					title="Consulta Automatizada Para Fins De Construção"
					closeModal={closeModal}
					pdfUrl={pdfUrl}
				/>
			)}
			{loading && <Waiter />}
			<Section>
				<Jumbotron style={{ background: 'transparent' }}>
					<h1 className="display-6">
						Consulta Automatizada para Fins De Construção
					</h1>
					<p className="lead">
						Secretaria Municipal de Desenvolvimento Urbano
					</p>
					{erro && <span style={{ color: '#ff1912' }}>{erro}</span>}
					<hr className="my-2" />
					<br />
					<Form onSubmit={(e) => e.preventDefault()}>
						<FormGroup>
							<Label htmlFor="inscricao_imobiliaria">
								Inscrição imobiliária
							</Label>
							<InputMask
								type="text"
								mask="99.99.999.9999.999-999"
								className="form-control"
								name="inscricao_imobiliaria"
								id="inscricao_imobiliaria"
								maskChar="_"
								placeholder="Ex: 12.34.567.8910.111-213"
								value={inscription}
								onChange={({ target: { value } }) =>
									setInscription(value)
								}
							/>
						</FormGroup>
						<FormGroup>
							<Label htmlFor="usos">Usos</Label>
							<CustomSelect
								value={usage}
								onChange={(e) => setUsage(e)}
								placeholder="Selecione as categorias"
								options={atividades.map((e) => ({
									label: `${String(
										e.descricao
									).trim()}`,
									labelAlias: (
										<>
											{e.descricao &&
												e.descricao.trim()}
										</>
									),
									value: e.id,
								}))}
							/>
						</FormGroup>
						<FormGroup>
							<Button
								onClick={gerarConsulta}
								color="info"
								type="submit"
							>
								Gerar
							</Button>
						</FormGroup>
					</Form>
				</Jumbotron>
			</Section>
		</>
	);
}

export default FormConsultaConstrucao;
