import React from 'react';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga4';
import Routes from './routes';
import store from './store';
import GlobalStyles from './styles/global';

if (
	process.env.REACT_APP_GA_MEASUREMENT_ID &&
	process.env.REACT_APP_GA_MEASUREMENT_ID.trim() !== ''
) {
	ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
}

const App = () => (
	<Provider store={store}>
	
		<Routes />
		<GlobalStyles />
	</Provider>
);

export default App;
