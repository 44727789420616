import React from 'react';

import { Jumbotron, Table, Button } from 'reactstrap';

import Footer from '~/Components/Footer';
import { host } from '~/config/api';
import tutorialAtualizado from '~/assets/documents/tutorial_atualizado.pdf';

import { Content, Section, ButtonGroup } from './styles';

const Geoservices = () => {
	function openLink(href) {
		const link = document.createElement('a');
		link.href = href;
		link.target = '_blank';
		link.click();
	}

	return (
		<>
			<Content>
				<Section>
					<Jumbotron style={{ background: 'transparent' }}>
						<h1 className="display-6">CATÁLOGO DE GEOSERVIÇOS</h1>
						<p className="lead">
							Geoserviço é uma tecnologia de compartilhamento de dados geográficos, como mapas, imagens de satélite e informações de localização, para fornecer uma variedade de funcionalidades e recursos em diferentes aplicações e plataformas, especialmente por meio de navegador de internet ou software de geoprocessamento.
						</p>

						<Table striped hover>
							<thead>
								<tr>
									<th className="p-3">INSTITUIÇÃO</th>
									<th className="p-3">SERVIÇOS</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td
										className="p-2 align-middle"
										style={{ width: '100%' }}
									>
										Geofloripa - Sistema de Geoprocessamento
									</td>
									<td className="p-2 text-right align-middle">
										<ButtonGroup>
											<Button
												color="info"
												size="sm"
												onClick={() =>
													openLink(
														`${host}geoserver/Geoportal/ows?service=wms&version=1.3.0&request=GetCapabilities`
													)
												}
											>
												WMS
											</Button>
											{/* <Button
												color="info"
												size="sm"
												onClick={() =>
													openLink(
														`${host}geoserver/Geoportal/ows?service=wfs&version=1.3.0&request=GetCapabilities`
													)
												}
											>
												WFS
											</Button> */}
											<Button
												color="success"
												size="sm"
												onClick={() => openLink('/map')}
											>
												Mapas
											</Button>
										</ButtonGroup>
									</td>
								</tr>
							</tbody>
						</Table>
					</Jumbotron>
				</Section>
			</Content>
			<Footer />
		</>
	);
};

export default Geoservices;
