import React, { useState, useRef } from 'react';
import { MdExpandMore, MdClose } from 'react-icons/md';
import { Dropdown, DropdownItem } from 'reactstrap';

import {
	ItensContent,
	ControlContent,
	ItemSelected,
	ItemSelectedFrst,
	ItemSelectedScnd,
	OptionsMenu,
	Toggle,
	MenuItem,
	ItemsContainer,
	SearchContainer,
	Placeholder,
} from './styles';

const CustomSelect = ({
	options = [],
	onChange = () => {},
	value = [],
	placeholder,
}) => {
	const [opened, setOpened] = useState(false);
	const [block, setBlocked] = useState(false);
	const [filter, setFilter] = useState('');
	const inputSearch = useRef(null);

	function handleSetOpened(val) {
		setFilter('');
		setOpened(val);
	}

	return (
		<Dropdown
			style={{ width: '100%' }}
			isOpen={opened}
			toggle={() => {
				if (!block) {
					handleSetOpened(!opened);
				}

				if (!opened) {
					setTimeout(() => {
						inputSearch.current.focus();
					}, 100);
				}

				setBlocked(false);
			}}
		>
			<Toggle tag="button">
				<ItensContent>
					{!value[0] && <Placeholder>{placeholder}</Placeholder>}
					{value.map((e, i) => {
						const [obj] = options.filter((f) => f.value === e);

						return (
							<ItemSelected key={String(i)}>
								<ItemSelectedFrst>{obj.label}</ItemSelectedFrst>
								<ItemSelectedScnd
									onMouseDown={() => setBlocked(true)}
									onClick={() =>
										onChange(value.filter((f) => f !== e))
									}
								>
									<MdClose />
								</ItemSelectedScnd>
							</ItemSelected>
						);
					})}
				</ItensContent>

				<ControlContent>
					<MdClose
						size={16}
						onMouseDown={() => !opened && setBlocked(true)}
						onClick={() => onChange([])}
					/>
					<span>|</span>
					<MdExpandMore size={22} />
				</ControlContent>
			</Toggle>
			<OptionsMenu>
				<SearchContainer>
					<input
						className="form-control"
						ref={inputSearch}
						type="search"
						placeholder="Filtrar"
						value={filter}
						onChange={({ target: { value: v } }) => setFilter(v)}
					/>
				</SearchContainer>

				<DropdownItem divider />

				<ItemsContainer>
					{(() => {
						const filtered = options.filter(
							(e) =>
								!value.includes(e.value) &&
								(e.label.toUpperCase().includes(filter.toUpperCase()) ||
								String(e.value).toUpperCase().includes(filter.toUpperCase()))
						);

						return filtered[0] ? (
							filtered.map((e, i) => (
								<MenuItem
									key={String(i)}
									onClick={() =>
										onChange([...value, e.value])
									}
								>
									{e.labelAlias || e.label}
								</MenuItem>
							))
						) : (
							<MenuItem disabled>Nada para mostrar</MenuItem>
						);
					})()}
				</ItemsContainer>
			</OptionsMenu>
		</Dropdown>
	);
};

export default CustomSelect;
