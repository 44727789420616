import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import { Input, FormGroup, Label, Button, Jumbotron, Form } from 'reactstrap';

import CustomSelect from '~/Components/CustomSelect';
import Waiter from '~/Components/Waiter';

import { Section } from '../styles';

import api, { signIn } from '../../../services/api';

import { cpf, cnpj } from 'cpf-cnpj-validator'; 
import PDFModal from '~/Components/PDFModal';

function FormConsultaInstalacao() {
	const [modalVisivel, setModalVisivel] = useState(false);
	const [loading, setLoading] = useState(false);
	const [atividades, setAtividades] = useState([]);
	const [inscricao, setInscricao] = useState('');
	const [name, setName] = useState('');
	const [cpf_cnpj, setCPF_CNPJ] = useState('');
	const [cnaes, setCNAES] = useState([]);
	const [erro, setErro] = useState('');
	const [pdfUrl, setPdfURL] = useState('')

	useEffect(() => {
		async function getAtividades() {
			await signIn();

			const response = await api.get('urbano/relatorios/get_atividades', {
				headers: {
					'Content-Type': 'application/json',
				},
			});

			if (response.data.atividades) {
				setAtividades(response.data.atividades);
			}
		}

		getAtividades();
	}, []);

	async function gerarConsulta() {

		try {
			setLoading(true);
			const { data } = await api.post('/urbano/relatorios/consulta_viabilidade_para_instalacao',{
				inscricao,
				cnaes,
			})
			if(!data?.success || !data?.report_response?.pdf_url){
				setErro(data?.message || 'Houve um erro ao gerar documento')
			}

			setPdfURL(data.report_response.pdf_url)
			setLoading(false)
			setModalVisivel(true)
			setErro('')
		} catch (error) {
			if (error.isAxiosError) {
				setErro(
					error.response.data.message ||
						'Houve um erro ao gerar documento'
				);
			} else {
				setErro('Informações da consulta não encontradas.');
			}
			setLoading(false);
		}
	}

	function closeModal() {
		setModalVisivel(false);
	}

	return (
		<>
			{modalVisivel && (
				<PDFModal
					title='Consulta Viabilidade Instalação'
					closeModal={closeModal}
					pdfUrl={pdfUrl}
				/>
			)}
			<Section>
				<Jumbotron style={{ background: 'transparent' }}>
					<h1 className="display-6">
						Consulta de Viabilidade para Instalação
					</h1>
					<p className="lead">
						Secretaria Municipal de Desenvolvimento Urbano
					</p>
					{erro && <span style={{ color: '#ff1912' }}>{erro}</span>}
					<hr className="my-2" />
					<br />
					<Form onSubmit={(e) => e.preventDefault()}>
						<FormGroup>
							<Label htmlFor="inscricao_imobiliaria">
								Inscrição imobiliária
							</Label>
							<InputMask
								type="text"
								mask="99.99.999.9999.999-999"
								className="form-control"
								name="inscricao_imobiliaria"
								id="inscricao_imobiliaria"
								placeholder="Ex: 12.34.567.8910.111-213"
								maskChar="_"
								value={inscricao}
								onChange={({ target: { value } }) =>
									setInscricao(value)
								}
							/>
						</FormGroup>
						<FormGroup>
							<Label htmlFor="usos">
								CNAEs (Classificação Nacional de Atividades
								Econômicas)
							</Label>
							<CustomSelect
								value={cnaes}
								onChange={(e) => setCNAES(e)}
								placeholder="Selecione as categorias"
								options={atividades.map((e) => ({
									label: `${String(
										e.fields.codigo
									).trim()} - ${e.fields.nome.trim()}`,
									labelAlias: (
										<>
											<strong>{e.fields.codigo}</strong> -{' '}
											{e.fields.nome.trim()}
										</>
									),
									value: e.fields.codigo,
								}))}
							/>
						</FormGroup>
						<FormGroup>
							<Button
								onClick={gerarConsulta}
								color="info"
								type="submit"
							>
								Gerar
							</Button>
						</FormGroup>
					</Form>
				</Jumbotron>
			</Section>
			{loading && <Waiter />}
		</>
	);
}

export default FormConsultaInstalacao;
