import React from 'react';

export default [
	{
		name: 'Sistema Municipal de informações urbanísticas',
		id: 'sistema_municipal_de_informacoes_urbanisticas',
		content: (
			<>
				<p><strong>GeoFloripa</strong></p>
				<p>O GeoFloripa consiste no software de gestão territorial do município de Florianópolis, que compõe o Sistema Municipal de Informações Urbanísticas e Cartografia Oficial, conforme Decreto n.º 26.184/2024.</p>
				<p>O GeoFloripa é um sistema de Cadastro Territorial Multifinalitário, composto por dados e informações relativos aos imóveis do cadastro territorial municipal, dados e informações dos cadastros temáticos municipais, bem como a cartografia e a geoinformação oficial municipal.</p>
				<p>Composto por diferentes módulos, com dados e informações geridas por grande parte das secretarias e órgãos municipais continuamente, o sistema serve de base cartográfica para os atos oficiais no âmbito municipal, conforme art. 323 da Lei Complementar n.º 482/2014, alterada pela Lei Complementar n.º 739/2023, e Decreto n.º 26.184/2024.</p>
				<p>O software é baseado em tecnologias livres, contendo mapas temáticos, ortofotos e ortomosaicos integrados com dados vetoriais e alfanuméricos. Permite a manutenção da base cartográfica oficial do município, sincronizada com base de dados alfanumérica, mantendo a integridade e qualidade dos dados disponíveis. Além disso, possibilita o cadastro de objetos territoriais, feições, análises espaciais e geração de mapas para impressão.</p>

				<p><strong>GeoPortal</strong></p>
				<p>O GeoPortal consiste na plataforma da Infraestrutura de Dados Espaciais - IDE do município de Florianópolis, que compõe o Sistema Municipal de Informações Urbanísticas e Cartografia Oficial, conforme Decreto n.º 26.184/2024.7</p>
				<p>O portal fornece diversos serviços ao cidadão, objetivando promover maior celeridade nos processos, como a emissão de Consulta de Viabilidade para Instalação, Consulta Automatizada para Fins de Construção, Consulta Ambiental, Certidão de Endereço e Certidão de Confrontantes. Além disso, busca conferir transparência aos dados, especialmente por meio do mapa interativo e da área de downloads.</p>
				<p>No GeoPortal, está disponível a base cartográfica oficial no âmbito municipal, conforme art. 323 da Lei Complementar n.º 482/2014, alterada pela Lei Complementar n.º 739/2023, e Decreto n.º 26.184/2024.</p>

				<p><strong>Sobre o Sistema</strong></p>
				<p>O GeoFloripa utiliza as linguagens de back-end denominada Ruby, associada ao framework Ruby on Rails, e recursos de programação front-end HTML5, CSS, Java Script, ExtJS e Open Layers e como ambiente de desenvolvimento foi utilizado o Microsoft Visual Studio Code, para a ferramenta de cadastro.</p>

				<p><strong>Características técnicas da plataforma Web</strong></p>
				<ul>
					<li>Sistema Operacional (SO): Linux Ubuntu Server 18.04 LTS.</li>
					<li>Software de Geoprocessamento Web: Geoserver</li>
					<li>Software de Geoprocessamento Desktop: QGIS</li>
					<li>Gerenciador de Banco de Dados: software PostgreSQL® com o PostGIS®</li>
					<li>Software de Banco de Dados: PG Admin IV e DBeaver</li>
					<li>Banco de Dados: PostgreSQL</li>
					<li>Extensão Espacial: PostGIS</li>
					<li>Browser Suportados: Microsoft Edge 15, Google Chrome build 60.0.3112.112, Mozilla Firefox 54, Safari 11.0.3 ou superiores.</li>
				</ul>

			</>
		)
	},
	{
		name: 'Diretoria de Gestão Territorial',
		id: 'diretoria_gestão_territorial',
		content: (
			<>
				<p>
					A Diretoria de Gestão Territorial (DGT) da Secretaria Municipal de Planejamento e Inteligência Urbana (SMPIU) é responsável pela produção, manutenção e disponibilização de dados e informações territoriais oficiais do município.
				</p>
				<p>
					Principais áreas de atuação:
				</p>
				<ul>
					<li>Cadastro Multifinalitário;</li>
					<li>Base Territorial;</li>
					<li>Cartografia oficial;</li>
					<li>Geoprocessamento;</li>
					<li>Pesquisa;</li>
					<li>Leituras urbanas;</li>
					<li>Indicadores de Desempenho Urbano e Territorial.</li>
				</ul>
				<p>
					A DGT é formada pela Gerência de Cadastro Multifinalitário (GCM) e o Departamento de Cadastro e Base Territorial (DCAD), bem como a Gerência de Pesquisa e Indicadores Territoriais (GPIT) e o Departamento de Geoinformação e Pesquisa (DGEOP).
				</p>
				<p>
					A Diretoria é composta pelos seguintes membros:
				</p>
				<ul>
					<li>Jonatas Rafael dos Santos - Diretor de Gestão Territorial</li>
					<li>Luciano Rosa Rockett - Gerente de Cadastro Multifinalitário</li>
					<li>Ana Paula Kretzer de Miranda - Técnica em Edificações</li>
					<li>Breno Vilhalba Pamplona - Engenheiro Civil</li>
					<li>Elisa Bezerra Cabral - Geógrafa</li>
					<li>Gabriel Victor Schutz - Técnico em Edificações</li>
					<li>Juliana Amalia de Oliveira Britto - Bibliotecária</li>
					<li>Mateus Nilton de Souza - Técnico em Edificações</li>
					<li>Eduarda Quadros Leonardo - Estagiária em Geografia</li>
					<li>Weliton Ribeiro da Rosa - Estagiário em Geografia</li>
					<li>Camila Mayara Rabaioli - Secretária</li>
					<li>Leonardo Góes - Gerente de Pesquisa e Indicadores Territoriais</li>
					<li>Bianca Ana Coelho - Arquiteta e Urbanista</li>
					<li>Cleidir Eidelwein Bicca - Geógrafo</li>
					<li>Maria Luiza Tremel de Faria Lima - Arquiteta e Urbanista</li>
					<li>Solange Richartz Wilvert - Geógrafa </li>
					<li>Otávio Augusto Herpich - Estagiário em Geografia</li>
					<li>Caroliny Alves Leal - Secretária</li>
				</ul>
			</>
		),
	},
	{
		name: 'Geoprocessamento no município de Florianópolis',
		id: 'geoprocessamento_no_municipio_de_florianopolis',
		content: (
			<>
				<p>
					O conhecimento da realidade do município constitui a base fundamental para diversos aspectos do planejamento e gestão territorial, apoiando a criação de estratégias sustentáveis e adaptadas às características locais, promovendo o desenvolvimento econômico, social e ambiental de forma equitativa e inclusiva.
				</p>
				<p>
					Assim, o uso do geoprocessamento tem como objetivo a evolução e o desenvolvimento institucional do município de Florianópolis por meio da modernização da gestão cadastral, territorial e temática. Desse modo, forma-se uma infraestrutura de geoinformação unificada, com o compartilhamento, acessibilidade e transparência das informações entre as secretarias municipais, melhorando a qualidade da gestão.
				</p>
				<p>
					O Cadastro Territorial é a principal base de informações em que estão suportados os processos de arrecadação tributária, planejamento, ordenamento territorial e fiscalização. Além de outras ações setoriais - saúde, habitação, meio ambiente e infraestrutura - fundamentais para aquisição de informações, que dão suporte para a tomada de decisões e apropriação de recursos financeiros.
				</p>
				<p>
					Isso permite a implantação do Cadastro Territorial Multifinalitário, que promove a modernização da gestão territorial, a efetividade do processo de planejamento urbano e a ampliação na transparência das ações do poder público.
				</p>
				<p>
					<strong>HISTÓRICO</strong>
				</p>
				<p>
					Base Cartográfica
				</p>
				<p>
					Até o ano de 1996, o município de Florianópolis dispunha das seguintes bases cartográficas:
				</p>

				<ul>
					<li>Cobertura aerofotogramétrica preto e branco, na escala 1:20.000, executada no ano de 1938;</li>
					<li>Cobertura aerofotogramétrica preto e branco, na escala 1:25.000, executada no ano de 1957;</li>
					<li>Cobertura aerofotogramétrica preto e branco, na escala 1:20.000, executada no ano de 1969;</li>
					<li>Cobertura aerofotogramétrica preto e branco, na escala 1:25.000, executada no triênio 1976/1978;</li>
					<li>Cobertura aerofotogramétrica preto e branco, na escala 1:8.000 e 1:25.000, executada no ano de 1994;</li>
					<li>Rede de referência geodésica do município de Florianópolis.</li>
				</ul>

				<p>
					A cartografia do cadastro territorial no município até o ano de 1996 era resultante de uma restituição analógica em escala 1:10.000, do levantamento aerofotogramétrico executado no fim da década de 1970, com a escala nominal de 1:25.000. Essa foi a primeira base cartográfica desenvolvida pelo município e teve ampla utilização na elaboração de projetos no território municipal.
				</p>
				<p>
					A partir do ano de 1997, tendo em vista a desatualização da base cartográfica produzida em 1979, o Município de Florianópolis iniciou um programa cartográfico digital na escala de 1:2.000.
				</p>
				<p>
					No período de 1997 até 2004, foram mapeados por processo de restituição digital na escala de 1:2.000, todos os distritos do município, excetuando-se o Distrito Sede.
				</p>
				<p>
					Adicionalmente, foram confeccionadas as ortofotocartas digitais na escala de 1:2.000, dos distritos: Lagoa da Conceição, Barra da Lagoa, Ribeirão da Ilha, Pântano do Sul, Campeche, Canasvieiras, Cachoeira do Bom Jesus e Ratones, bem como foram confeccionadas as ortofotocartas na escala 1:5.000, a partir de cobertura aérea colorida na escala de 1:15.000, de todo o município no ano de 2002.
				</p>
				<p>
					<strong>Rede de Referência Geodésica</strong>
				</p>
				<p>
					A Rede de Referência Geodésica do município de Florianópolis é composta por 121 (cento e vinte e uma) estações, distribuídas em todos os distritos, exceto no Distrito Sede.
				</p>
				<p>
					Para a obtenção das coordenadas dessa rede foi utilizado o processo de Posicionamento Global por Satélite (GPS), amarrado à rede clássica do IBGE, tendo-se utilizado as estações Base Aérea (Florianópolis) e SG-10-2252 (Governador Celso Ramos). Para a obtenção das altitudes ortométricas das estações componentes da Rede de Referência Geodésica, foi utilizado o processo de nivelamento geométrico amarrado em referências de nível da rede de 1° ordem do IBGE.
				</p>

				<p>
					Cadastro Técnico Urbano

					O Cadastro Técnico Urbano foi implantado em 1982, através de um projeto de gestão da área conurbada dos municípios de Florianópolis, Biguaçu, São José e Palhoça, denominado como AUF - Aglomerado Urbano de Florianópolis. Foi criado um modelo cadastral para ser utilizado nestes municípios compreendendo uma nomenclatura de inscrição cadastral composta por: distritos, setores, quadras e lotes, adotando uma numeração cartesiana iniciando em 1 até 100, começando do norte/oeste (em Biguaçu) indo em direção sul.
				</p>
				<p>
					Dessa forma, foram criados em Florianópolis 31 distritos cadastrais com numeração variando entre 17 e 85, articulados cada um em 4 pranchas na escala 1:5.000 tendo como base uma cartografia na escala 1:10.000 (de 1979), e cada qual divididos em 100 setores (quadrantes). Partindo-se para um detalhamento maior, foram criadas plantas de quadra sob a nomenclatura de DDSSQQQ (distrito, setor, quadra), através de levantamentos em campo por topografia e a restituição de 1979. Nestas, foram transcritas informações do cadastro, tais como número do lote, número de correspondência, nomes e códigos/seções de logradouros.
				</p>
				<p>
					Essa base gráfica carece de precisão cartográfica para representar o cadastro técnico urbano atual frente às suas transformações sofridas ao longo do tempo, em que a interação entre as bases (gráfica e literal), tem um frágil elo, amparado por processos manuais de sincronismo e atualização geométrica sem georreferência, dificultando uma gestão cadastral adequada às necessidades do município.
				</p>
				<p>
					Na década de 90, foram efetuados recadastramentos imobiliários, procurando-se manter atualizadas as bases de informações. Em 2003, foi efetuado um comparativo do crescimento da base cadastral entre os anos 1994, 1998 e 2003, resultando em avaliação da perda em arrecadação na ordem de 2% a.a. e em área construída 6% a.a.
				</p>
				<p>
					Em 2005, foi lançado o PAC - Programa de Atualização Cadastral, visando atualizar de forma sistêmica a base de informações cadastrais, proporcionando uma visão real da situação da ocupação urbana do município.
				</p>
				<p>
					<strong>Geoprocessamento</strong>
				</p>
				<p>
					Em 2003, o Município desenvolveu seu primeiro projeto de geoprocessamento, integrante do PMAT - Programa de Modernização da Administração Tributária, sendo seu principal foco a estruturação do cadastro técnico, contendo informações da base cartográfica digital e codificação das informações do cadastro imobiliário.
				</p>
				<p>
					O projeto previu a estruturação da base cartográfica digital para ambiente SIG - Sistema de Informações Geográficas - e seu relacionamento com os dados literais do cadastro imobiliário, abrangendo a área sul do município (Distritos de Campeche, Ribeirão da Ilha e Pântano do Sul), sendo objeto de pesquisa, análise, cruzamento de informações.
				</p>
				<p>
					O Geoprocessamento Corporativo foi desenvolvido objetivando capacitar institucionalmente e aplicar soluções tecnológicas visando promover a modernização da Administração Municipal através de sua Gestão e Fiscalização Cadastral, da unificação e integração das Bases Territoriais, do Cadastro Técnico ao Geoprocessamento.
				</p>

				<p>
					Após a aquisição e adequação da base de informações do município todo o produto final foi disponibilizado no Portal Geoprocessamento Corporativo. As informações foram apresentadas num ambiente totalmente desenvolvido em tecnologia de software Livre, onde foram apresentados acervos de imagens e mapas temáticos.
				</p>
				<p>
					ComAtravés do uso de mapas temáticos a administração agiliza os processos de atendimento ao cidadão. Esta ferramenta é potencializada através do uso em conjunto com as ortofotos disponibilizadas pelo sistema, sendo possível a impressão via internet.
				</p>

				<p>
					<strong>GeoFloripa</strong>
				</p>

				<p>
					Em outubro de 2022, o sistema GeoFloripa substituiu o Geoprocessamento Corporativo enquanto principal ferramenta de gestão territorial.
				</p>
				<p>
					A solução de software GeoFloripa e o GeoPortal foram desenvolvidos nos anos de 2020 e 2021, por meio do contrato n.º 1066/IPUF/2019, decorrente do pregão eletrônico n.º 420/SMA/DSLC/2019, homologado em 06 de novembro de 2019, por intermédio do Instituto de Pesquisa e Planejamento Urbano de Florianópolis (IPUF), atualmente vinculado à Secretaria Municipal de Planejamento e Inteligência Urbana (SMPIU).
				</p>
				<p>
					As etapas do projeto foram: Planejamento do Projeto; Implantação do GeoFloripa; Customização; Serviços de Criação de Banco de Dados e Migração; Serviços de Treinamento; Manuais do Sistema e Serviços de Suporte Técnico e Manutenção.
				</p>
				<p>
					Em 2024, o Decreto n.º 26.184 instituiu o Sistema Municipal de Informações Urbanísticas e Cartografia Oficial para a gestão territorial de Florianópolis, nos termos do art. 321 da Lei Complementar n.º 482/2014, alterada pela Lei Complementar n.º 739/2023. Assim, definiu-se que o sistema de informações do município seria constituído pelo GeoFloripa (de acesso interno) e pelo GeoPortal (de acesso público).
				</p>
			</>
		),
	},
];
