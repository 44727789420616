import React, { useState } from 'react';
import { useHistory, Link, useRouteMatch } from 'react-router-dom';

import {
	Container,
	Header,
	Logo,
	LogoRdp,
	LogoContainer,
	RdpContainer,
	Menu,
	MenuItem,
	MenuContainer,
	RespContainer,
	MenuIcon,
	LogoMain,
} from './styles';

const Navbar = () => {
	const history = useHistory();

	const { path } = useRouteMatch();

	const [menuOpened, setMenuOpened] = useState(false);

	return (
		<Container>
			<Header>
				<RespContainer>
					<LogoMain>
						<LogoContainer onClick={() => history.push('/')}>
							<Logo />
							<span>Geoportal</span>
						</LogoContainer>
						<RdpContainer href="https://redeplanejamento.pmf.sc.gov.br/pt-BR" target="_blank">
							<LogoRdp />
						</RdpContainer>
					</LogoMain>
					<MenuIcon onClick={() => setMenuOpened(!menuOpened)} />
				</RespContainer>

				<MenuContainer>
					<Menu opened={menuOpened}>
						<MenuItem active={path === '/' || path === '/article'}>
							<Link to="/">Início</Link>
						</MenuItem>
						<MenuItem active={path === '/map'}>
							<Link to="/map">Mapas Interativos</Link>
						</MenuItem>
						<MenuItem
							active={
								path === '/downloads' ||
								path.includes('/downloads/')
							}
						>
							<Link to="/downloads">Download</Link>
						</MenuItem>
						<MenuItem highlight active={path === '/services'}>
							<Link to="/services">Emissão de Documentos</Link>
						</MenuItem>
						<MenuItem active={path === '/help'}>
							<Link to="/help">Ajuda</Link>
						</MenuItem>
					</Menu>
				</MenuContainer>
			</Header>
		</Container>
	);
};

export default Navbar;
