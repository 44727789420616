/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef } from 'react';
import { FiArrowRightCircle, FiArrowLeftCircle } from 'react-icons/fi';

import { Container, Content, ArticleCard, Image, Scroller } from './styles';

import news1 from '~/assets/img/slider1.jpg';
import news2 from '~/assets/img/slider2.jpg';
import news3 from '~/assets/img/slider3.jpg';
import news4 from '~/assets/img/slider4.jpg';

const ArticleCards = () => {
	const content = useRef(null);

	function scrollTo(element, to, duration) {
		if (duration <= 0) return;
		const difference = to - element.scrollLeft;
		const perTick = (difference / duration) * 10;

		setTimeout(() => {
			element.scrollLeft += perTick;
			if (element.scrollLeft === to) return;
			scrollTo(element, to, duration - 10);
		}, 10);
	}

	function handleScroll(isRight) {
		const { current } = content;
		if (isRight) {
			scrollTo(current, current.scrollLeft - 200, 100);
		} else {
			scrollTo(current, current.scrollLeft + 200, 100);
		}
	}

	return (
		<Container>
			<Scroller>
				<div onClick={() => handleScroll()}>
					<FiArrowLeftCircle size={25} />
				</div>
			</Scroller>
			<Scroller right>
				<div onClick={() => handleScroll(true)}>
					<FiArrowRightCircle size={25} />
				</div>
			</Scroller>
			<Content ref={content}>
				<ArticleCard className="container">
					<Image url={news1}>
						<h3>Evento técnico sobre GeoFloripa e Replan</h3>
					</Image>
				</ArticleCard>
				<ArticleCard className="container">
					<Image url={news2}>
						<h3>Equipe responsável pela organização e apresentação do evento</h3>
					</Image>
				</ArticleCard>
				<ArticleCard className="container">
					<Image url={news3}>
						<h3>Estavam participando do evento, Secretário de Planejamento, Michel de Andrado Mittmann, Superintendente Kaliu Teixeira, e o professor Dr. Everton da Silva</h3>
					</Image>
				</ArticleCard>
				<ArticleCard className="container">
					<Image url={news4}></Image>
				</ArticleCard>
			</Content>
		</Container>
	);
};

export default ArticleCards;
