import { css, createGlobalStyle } from 'styled-components';
import 'fontsource-montserrat';
import '~/assets/styles/bootstrap.min.css';

const styles = css`
	* {
		box-sizing: border-box;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	a {
		letter-spacing: normal !important;
		text-transform: none !important;
	}

	*:focus {
		outline: 0;
	}

	body,
	#root {
		height: 100vh;
		width: 100vw;
		display: flex;
		flex-direction: column;
		margin: 0;
		padding: 0;
		overflow: hidden;
		font-family: Montserrat, Roboto, Helvetica, sans-serif;
	}

	body,
	input,
	button {
		-webkit-font-smoothing: antialiased !important;
		outline: none;

		&:focus {
			outline: none;
		}
	}
`;

export default createGlobalStyle`
	${styles}
`;
