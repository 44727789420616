import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { Button, Jumbotron, Table } from 'reactstrap';

import { loadRequest } from '~/store/modules/downloads/actions';

import Footer from '~/Components/Footer';
import Waiter from '~/Components/Waiter';
import FileDownload from '~/Components/FileDownload';

import { Aside, Content, Section, Item } from './styles';

const Downloads = () => {
	const history = useHistory();
	const { downloadType } = useParams();

	const dispatch = useDispatch();
	const { data: downloads, loading, error } = useSelector((e) => e.downloads);
	const [atualDownload, setAtualDownload] = useState(null);

	useEffect(() => {
		if (!downloads) dispatch(loadRequest());
	}, [downloads, dispatch]);

	useEffect(() => {
		if (downloads && downloads[0]) {
			const [atual] = downloads.filter((e) => e.route === downloadType);

			if (!atual) {
				history.replace(`/downloads/${downloads[0].route}`);
			} else {
				setAtualDownload(atual || downloads[0]);
			}
		}
	}, [downloads, downloadType, history]);

	return (
		<>
			<Content>
				<Aside>
					<h3>Downloads</h3>
					{downloads?.map((e, i) => (
						<Item
							key={String(i)}
							selected={
								atualDownload && e.id === atualDownload.id
							}
							onClick={() =>
								history.push(`/downloads/${e.route}`)
							}
						>
							{e.description}
						</Item>
					))}
				</Aside>
				<Section>
					{loading && <Waiter style={{ position: 'absolute' }} />}

					{atualDownload && (
						<Jumbotron style={{ background: 'transparent' }}>
							<h1 className="display-6">
								{atualDownload.description}
								{error && 'Ops...'}
							</h1>
							<p className="lead">
								{!error &&
									!loading &&
									!atualDownload.downloads[0] &&
									'Esta categoria ainda não possui arquivos.'}

								{!(
									!error &&
									!loading &&
									!atualDownload.downloads[0]
								) &&
									atualDownload.complement?.trim() &&
									atualDownload.complement}

								{error &&
									'Houve num erro ao tentar carregar os documentos.'}
							</p>

							{error && (
								<p className="lead">
									<Button
										type="button"
										color="info"
										onClick={() => window.location.reload()}
									>
										Tentar novamente
									</Button>
								</p>
							)}

							<Table hover>
								<tbody>
									{atualDownload.downloads.map((e, i) => (
										<FileDownload
											key={String(i)}
											data={e}
										/>
									))}
								</tbody>
							</Table>
						</Jumbotron>
					)}
				</Section>
			</Content>
			<Footer />
		</>
	);
};

export default Downloads;
