import React, { useState, useEffect } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import Footer from '../../Components/Footer';
import FormConsultaConstrucao from './FormConsultaConstrucao';
import FormConsultaInstalacao from './FormConsultaInstalacao';
import FormConsultaAmbiental from './FormConsultaAmbiental';
import FormCertidaoEndereco from './FormCertidaoEndereco';
import FormCertidaoConfrontantes from './FormCertidaoConfrontantes';

import { Aside, Content, Item } from './styles';

const Service = () => {
	const { service } = useParams();
	const history = useHistory();

	const [selectedOption, setSelectedOption] = useState('consulta-instalacao');

	useEffect(() => {
		if (service) {
			setSelectedOption(service);
		}
	}, [service]);

	return (
		<>
			<Content>
				<Aside>
					<h3>Documentos</h3>
						<>
							<Item
								selected={
									selectedOption === 'consulta-instalacao'
								}
								onClick={() =>
									history.push(
										'/services/consulta-instalacao'
									)
								}
							>
								Consulta de Viabilidade para Instalação
							</Item>
							{ <Item
								selected={
									selectedOption === 'consulta-construcao'
								}
								onClick={() =>
									history.push(
										'/services/consulta-construcao'
									)
								}
							>
								Consulta Para Fins De Construção
							</Item> }
							<Item
								selected={
									selectedOption === 'consulta-ambiental'
								}
								onClick={() =>
									history.push('/services/consulta-ambiental')
								}
							>
								Consulta Ambiental
							</Item>
							<Item
								selected={
									selectedOption === 'certidao-endereco'
								}
								onClick={() =>
									history.push('/services/certidao-endereco')
								}
							>
								Certidão de Endereço
							</Item>
							<Item
								selected={
									selectedOption === 'certidao-confrontantes'
								}
								onClick={() =>
									history.push('/services/certidao-confrontantes')
								}
							>
								Certidão Confrontantes
							</Item>
						</>
				</Aside>

				{selectedOption === 'consulta-construcao' && (
					<FormConsultaConstrucao />
				)}
				{selectedOption === 'consulta-instalacao' && (
					<FormConsultaInstalacao />
				)}
				{selectedOption === 'consulta-ambiental' && (
					<FormConsultaAmbiental />
				)}
				{selectedOption === 'certidao-endereco' && (
					<FormCertidaoEndereco />
				)}
				{selectedOption === 'certidao-confrontantes' && (
					<FormCertidaoConfrontantes />
				)}
			</Content>
			<Footer />
		</>
	);
};

export default Service;
