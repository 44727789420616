import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { FormGroup, Label, Button, Jumbotron, Form } from 'reactstrap';

import Waiter from '~/Components/Waiter';

import { Section } from '../styles';

import api from '../../../services/api';
import PDFModal from '~/Components/PDFModal';

function FormCertidaoEndereco() {
	const [modalVisivel, setModalVisivel] = useState(false);
	const [loading, setLoading] = useState(false);
	const [inscricao, setInscricao] = useState('');
	const [erro, setErro] = useState('');
	const [pdfUrl, setPdfURL] = useState('')

	async function gerarConsulta() {
		console.log(inscricao)
		if (inscricao === '' || inscricao.length < 22){
			setErro('Inscrição imboliliária incompleta.')
			return false
		}

		try {
			setLoading(true);
			const { data } = await api.post('/urbano/relatorios/certidao_atualizacao_endereco',{
				inscricao,
			})
			if(!data?.success || !data?.report_response?.pdf_url){
				setErro(data?.message || 'Houve um erro ao gerar documento')
			}

			setPdfURL(data.report_response.pdf_url)
			setLoading(false)
			setModalVisivel(true)
			setErro('')
		} catch (error) {
			if (error.isAxiosError) {
				setErro(
					error.response.data.message ||
						'Houve um erro ao gerar documento'
				);
			} else {
				setErro('Houve um erro ao gerar documento');
			}
			setLoading(false);
		}
	}

	function closeModal() {
		setModalVisivel(false);
	}

	return (
		<>
			{modalVisivel && (
				<PDFModal
					title='Certidão de Endereço'
					closeModal={closeModal}
					pdfUrl={pdfUrl}
				/>
			)}
			{loading && <Waiter />}
			<Section>
				<Jumbotron style={{ background: 'transparent' }}>
					<h1 className="display-6">
						Certidão de Endereço
					</h1>
					<p className="lead">
						Secretaria Municipal de Planejamento e Inteligência Urbana
					</p>
					{erro && <span style={{ color: '#ff1912' }}>{erro}</span>}
					<hr className="my-2" />
					<br />
					<Form onSubmit={(e) => e.preventDefault()}>
						<FormGroup>
							<Label htmlFor="inscricao_imobiliaria">
								Inscrição imobiliária
							</Label>
							<InputMask
								type="text"
								mask="99.99.999.9999.999-999"
								className="form-control"
								name="inscricao_imobiliaria"
								id="inscricao_imobiliaria"
								maskChar=""
								placeholder="Ex: 12.34.567.8910.111-213"
								value={inscricao}
								onChange={({ target: { value } }) =>
									setInscricao(value)
								}
							/>
						</FormGroup>
						<FormGroup>
							<Button
								onClick={gerarConsulta}
								color="info"
								type="submit"
							>
								Gerar
							</Button>
						</FormGroup>
					</Form>
				</Jumbotron>
			</Section>
		</>
	);
}

export default FormCertidaoEndereco;
