import styled from 'styled-components';

const DISPLAY_WIDTH = '630px';

export const Container = styled.div`
	background-color: rgba(255, 255, 255, 1);
	padding: 30px;
	width: 100%;
	padding: 40px 0;
	z-index: 99999;
`;

export const MiniCards = styled.div`
	display: flex;
	flex-wrap: wrap;
`;


export const Content = styled.div`
	display: flex;
	flex-wrap: nowrap;
	width: 100%;
	max-width: 1180px;
	color: #fff;
	justify-content: center;
    align-items: center;
	margin: 0 auto;
	@media screen and (min-width: 890px) and (max-width: 1180px) {
		max-width: 890px;
	}
	@media screen and (min-width: 580px) and (max-width: 889px) {
		width: 100%;
		max-width: 100%;
	}
`;
